import { map, prop, pathOr, applySpec } from "ramda";
import {
  Invoice,
  Item,
  ItemInput,
  Job,
  PartsOrderingIssuesSurveyInput,
  PreJobCheckListV2,
  UpdateJobInput,
} from "../../generated/nest-graphql";
import {
  formValuesToEJIDiscountInputs,
  formValuesToEJIPromoCodeInputs,
  formValuesToEJIServiceInputsSpec,
} from "../../specs/ejiServicesSpec";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";
import { emptyCheckList, oilLifeSpec, padThicknessSpec, tireTreadSpec } from "../Invoices/invoiceDetailsSpec";
import { checkListItemDefault } from "../../contexts/inspection-context";
import { PartsOrderingSurveyFormValues } from "../Forms/Surveys/PartsOrderingSurveyForm";
import { PreJobCheckListV2FormValues } from "../PreJobChecklistV2/PreJobCheckListV2Dialog";
import { PostJobCheckListFormValues } from "../PostJobChecklist/PostJobCheckListDialog";
import { inspectedServicesInitialState } from "../../contexts/inspection-context-v2";
import { PostJobCheckList } from "../PostJobChecklist/types";
import { WITH_ESTIMATE_TIMELINE } from "../PostJobChecklist/constants";

export const itemsSpec = (items: readonly Item[]) =>
  map((item: Item): ItemInput => {
    const { product, partsCost, partsStore, amount, description, laborCost, name, isInEstimate, partNumber } = item;
    return {
      product: product,
      partsCost,
      partNumber,
      partsStore: partsStore?.id,
      isInEstimate,
      name,
      laborCost,
      description,
      amount,
    };
  }, items);

export const preJobCheckListItemSpec = (checkListItem: any) => {
  const { partCondition, additionalInfo } = checkListItem;
  return {
    partCondition,
    additionalInfo,
  };
};

export const partsOrderingIssuesSurveysSpec = (partsOrderingIssuesSurveys: PartsOrderingSurveyFormValues[]) =>
  partsOrderingIssuesSurveys?.map(
    applySpec<PartsOrderingIssuesSurveyInput>({
      partsCorrect: prop("partsCorrect"),
      partsStores: prop("partsStores"),
      partsIssues: prop("partsIssues"),
      additionalDetails: prop("additionalDetails"),
      completed: prop("completed"),
      submittedBy: prop("submittedBy"),
    })
  );

export const updateJobDetailsSpec = (formData: JobDetailsFormValues): UpdateJobInput => {
  const {
    contact,
    partsLocation,
    partsOrdered,
    estimate,
    partsOrderNumber,
    extraInfo,
    symptoms,
    frontPadLife,
    licensePlate,
    make,
    model,
    odometer,
    rearPadLife,
    vin,
    market,
    partsNotes,
    year,
    appointmentId,
    description,
    items,
    jobName,
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    warrantyCheckReason,
    warrantyCheckReasonExtraInfo,
    warrantyCheckTechReason,
    warrantyCheckTechReasonExtraInfo,
    status,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    additionalNotes,
    rating,
    doneBetter,
    additionalComments,
    partsOrderingIssuesSurveys,
    partsOrderingIssuesSurvey,
    preJobCheckList,
    preJobCheckListV2,
    services,
    discounts,
    promoCodes,
    viocStore,
  } = formData;
  const {
    frontPads,
    frontPadsV2,
    frontRotors,
    rearPads,
    rearPadsV2,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    studs,
    lugnuts,
    sparkPlugs,
    carBattery,
    alternator,
    engineAirFilter,
    cabinAirFilter,
    otherBrakeParts,
    dashboardLights,
    oilLife,
    tireTread,
  } = preJobCheckList ?? emptyCheckList;
  return {
    contact: prop("id", contact),
    viocStore: prop("id", viocStore),
    feedback: rating
      ? {
          // @ts-ignore
          rating: parseInt(rating),
          doneBetter,
          additionalComments,
        }
      : null,
    partsOrderingIssuesSurvey,
    partsOrderingIssuesSurveys: partsOrderingIssuesSurveysSpec(partsOrderingIssuesSurveys),
    description,
    items: items && itemsSpec(items),
    jobName,
    estimate,
    market,
    appointment: appointmentId,
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontPadsV2: frontPadsV2 && padThicknessSpec(frontPadsV2),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearPadsV2: rearPadsV2 && padThicknessSpec(rearPadsV2),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      studs: studs && preJobCheckListItemSpec(studs),
      lugnuts: lugnuts && preJobCheckListItemSpec(lugnuts),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      carBattery: carBattery && preJobCheckListItemSpec(carBattery),
      alternator: alternator && preJobCheckListItemSpec(alternator),
      engineAirFilter: engineAirFilter && preJobCheckListItemSpec(engineAirFilter),
      cabinAirFilter: cabinAirFilter && preJobCheckListItemSpec(cabinAirFilter),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
      oilLife: oilLife && oilLifeSpec(oilLife),
      tireTread: tireTread && tireTreadSpec(tireTread),
    },
    preJobCheckListV2,
    partsInfo: {
      partsLocation,
      partsOrdered,
      partsOrderNumber,
      partsNotes,
    },
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    status,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    warrantyCheckReason,
    warrantyCheckReasonExtraInfo,
    warrantyCheckTechReason,
    warrantyCheckTechReasonExtraInfo,
    vehicleInfo: {
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      extraInfo,
      symptoms,
      vin,
      year,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
      additionalNotes,
    },
    services: formValuesToEJIServiceInputsSpec(services),
    discounts: formValuesToEJIDiscountInputs(discounts),
    promoCodes: formValuesToEJIPromoCodeInputs(promoCodes),
  };
};

export const preJobCheckListUpdateJobSpec = (preJobCheckListValues: PreJobCheckListFormValues) => {
  const {
    frontPads,
    frontPadsV2,
    frontRotors,
    rearPads,
    rearPadsV2,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    studs,
    lugnuts,
    sparkPlugs,
    carBattery,
    alternator,
    engineAirFilter,
    cabinAirFilter,
    otherBrakeParts,
    dashboardLights,
    odometer,
    oilLife,
    tireTread,
  } = preJobCheckListValues ?? {};
  return {
    vehicleInfo: {
      odometer,
    },
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontPadsV2: frontPadsV2 && padThicknessSpec(frontPadsV2),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearPadsV2: rearPadsV2 && padThicknessSpec(rearPadsV2),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      studs: studs && preJobCheckListItemSpec(studs),
      lugnuts: lugnuts && preJobCheckListItemSpec(lugnuts),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      carBattery: carBattery && preJobCheckListItemSpec(carBattery),
      alternator: alternator && preJobCheckListItemSpec(alternator),
      engineAirFilter: engineAirFilter && preJobCheckListItemSpec(engineAirFilter),
      cabinAirFilter: cabinAirFilter && preJobCheckListItemSpec(cabinAirFilter),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
      oilLife: oilLife && oilLifeSpec(oilLife),
      tireTread: tireTread && tireTreadSpec(tireTread),
    },
  };
};

export const preJobCheckListV2UpdateJobSpec = (preJobCheckListValues: PreJobCheckListV2FormValues) => {
  return {
    vehicleInfo: {
      odometer: preJobCheckListValues?.odometer,
      licensePlate: preJobCheckListValues?.licensePlate,
      licensePlateState: preJobCheckListValues?.licensePlateState,
    },
    preJobCheckListV2: {
      odometer: preJobCheckListValues?.odometer,
      brakeFluid: preJobCheckListValues?.brakeFluid && {
        level: preJobCheckListValues?.brakeFluid?.level,
        colorGrade: preJobCheckListValues?.brakeFluid?.colorGrade,
        ppm: preJobCheckListValues?.brakeFluid?.ppm,
      },
      privateNotes: preJobCheckListValues?.privateNotes,
      frontPads: preJobCheckListValues?.frontPads && {
        condition: preJobCheckListValues?.frontPads?.condition,
        padsThickness: preJobCheckListValues?.frontPads?.padsThickness,
        innerPadThickness: preJobCheckListValues?.frontPads?.innerPadThickness,
        outerPadThickness: preJobCheckListValues?.frontPads?.outerPadThickness,
      },
      frontRotors: preJobCheckListValues?.frontRotors && {
        condition: preJobCheckListValues?.frontRotors?.condition,
        thicknessInches: preJobCheckListValues?.frontRotors?.thicknessInches,
      },
      rearPads: preJobCheckListValues?.rearPads && {
        condition: preJobCheckListValues?.rearPads?.condition,
        padsThickness: preJobCheckListValues?.rearPads?.padsThickness,
        innerPadThickness: preJobCheckListValues?.rearPads?.innerPadThickness,
        outerPadThickness: preJobCheckListValues?.rearPads?.outerPadThickness,
      },
      rearRotors: preJobCheckListValues?.rearRotors && {
        condition: preJobCheckListValues?.rearRotors?.condition,
        thicknessInches: preJobCheckListValues?.rearRotors?.thicknessInches,
      },
      usesRearShoesAndDrums: preJobCheckListValues?.usesRearShoesAndDrums,
      rearShoes: preJobCheckListValues?.rearShoes && {
        colorGrade: preJobCheckListValues?.rearShoes?.colorGrade,
        condition: preJobCheckListValues?.rearShoes?.condition,
      },
      rearDrums: preJobCheckListValues?.rearDrums && {
        colorGrade: preJobCheckListValues?.rearDrums?.colorGrade,
        condition: preJobCheckListValues?.rearDrums?.condition,
      },
      inspectedServices: preJobCheckListValues?.inspectedServices && {
        frontLeftCaliper: preJobCheckListValues?.inspectedServices?.frontLeftCaliper && {
          colorGrade: preJobCheckListValues?.inspectedServices?.frontLeftCaliper?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.frontLeftCaliper?.included,
          notes: preJobCheckListValues?.inspectedServices?.frontLeftCaliper?.notes,
        },
        frontRightCaliper: preJobCheckListValues?.inspectedServices?.frontRightCaliper && {
          colorGrade: preJobCheckListValues?.inspectedServices?.frontRightCaliper?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.frontRightCaliper?.included,
          notes: preJobCheckListValues?.inspectedServices?.frontRightCaliper?.notes,
        },
        rearLeftCaliper: preJobCheckListValues?.inspectedServices?.rearLeftCaliper && {
          colorGrade: preJobCheckListValues?.inspectedServices?.rearLeftCaliper?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.rearLeftCaliper?.included,
          notes: preJobCheckListValues?.inspectedServices?.rearLeftCaliper?.notes,
        },
        rearRightCaliper: preJobCheckListValues?.inspectedServices?.rearRightCaliper && {
          colorGrade: preJobCheckListValues?.inspectedServices?.rearRightCaliper?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.rearRightCaliper?.included,
          notes: preJobCheckListValues?.inspectedServices?.rearRightCaliper?.notes,
        },
        frontLeftHubAssemblyBearing: preJobCheckListValues?.inspectedServices?.frontLeftHubAssemblyBearing && {
          colorGrade: preJobCheckListValues?.inspectedServices?.frontLeftHubAssemblyBearing?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.frontLeftHubAssemblyBearing?.included,
          notes: preJobCheckListValues?.inspectedServices?.frontLeftHubAssemblyBearing?.notes,
        },
        frontRightHubAssemblyBearing: preJobCheckListValues?.inspectedServices?.frontRightHubAssemblyBearing && {
          colorGrade: preJobCheckListValues?.inspectedServices?.frontRightHubAssemblyBearing?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.frontRightHubAssemblyBearing?.included,
          notes: preJobCheckListValues?.inspectedServices?.frontRightHubAssemblyBearing?.notes,
        },
        rearLeftHubAssemblyBearing: preJobCheckListValues?.inspectedServices?.rearLeftHubAssemblyBearing && {
          colorGrade: preJobCheckListValues?.inspectedServices?.rearLeftHubAssemblyBearing?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.rearLeftHubAssemblyBearing?.included,
          notes: preJobCheckListValues?.inspectedServices?.rearLeftHubAssemblyBearing?.notes,
        },
        rearRightHubAssemblyBearing: preJobCheckListValues?.inspectedServices?.rearRightHubAssemblyBearing && {
          colorGrade: preJobCheckListValues?.inspectedServices?.rearRightHubAssemblyBearing?.colorGrade,
          included: preJobCheckListValues?.inspectedServices?.rearRightHubAssemblyBearing?.included,
          notes: preJobCheckListValues?.inspectedServices?.rearRightHubAssemblyBearing?.notes,
        },
      },
    } as PreJobCheckListV2,
  };
};

export const postJobCheckListUpdateJobSpec = (
  postJobCheckListValues: PostJobCheckListFormValues,
  proceedingWithRepairs?: boolean
) => {
  let postJobChecklist: PostJobCheckList = {
    finalRecomendation: null,
    recommendedServices: null,
    notes: null,
    estimatedTimeline: null,
    needOutstandingRepair: null,
    wheelStuds: postJobCheckListValues.wheelStuds,
    lugnuts: postJobCheckListValues.lugnuts,
    dashboardLights: postJobCheckListValues.dashboardLights,
    testDriveExpirience: postJobCheckListValues.testDriveExpirience,
    otherConcerns: postJobCheckListValues.otherConcerns,
  };
  if (proceedingWithRepairs) {
    postJobChecklist.needOutstandingRepair = postJobCheckListValues.needOutstandingRepair;
  }
  if (postJobCheckListValues.needOutstandingRepair || !proceedingWithRepairs) {
    postJobChecklist.notes = postJobCheckListValues.notes;
    postJobChecklist.finalRecomendation = postJobCheckListValues.finalRecomendation;
    if (WITH_ESTIMATE_TIMELINE.includes(postJobCheckListValues.finalRecomendation ?? "")) {
      postJobChecklist.estimatedTimeline = postJobCheckListValues.estimatedTimeline;
    } else {
      postJobChecklist.recommendedServices = postJobCheckListValues.recommendedServices;
    }
  }
  return {
    postJobChecklist,
  };
};

export const postJobCheckListInitialValues = (source: Job | Invoice) => {
  return {
    finalRecomendation: pathOr("", ["postJobChecklist", "finalRecomendation"], source),
    recommendedServices: pathOr([], ["postJobChecklist", "recommendedServices"], source),
    notes: pathOr("", ["postJobChecklist", "notes"], source),
    estimatedTimeline: pathOr(null, ["postJobChecklist", "estimatedTimeline"], source),
    needOutstandingRepair: pathOr(false, ["postJobChecklist", "needOutstandingRepair"], source),
    wheelStuds: pathOr(null, ["postJobChecklist", "wheelStuds"], source),
    lugnuts: pathOr(null, ["postJobChecklist", "lugnuts"], source),
    dashboardLights: pathOr(null, ["postJobChecklist", "dashboardLights"], source),
    testDriveExpirience: pathOr(null, ["postJobChecklist", "testDriveExpirience"], source),
    otherConcerns: pathOr(null, ["postJobChecklist", "otherConcerns"], source),
  };
};

export const preJobCheckListInitialValues = (source: Job | Invoice) => {
  return {
    frontPads: pathOr(checkListItemDefault, ["preJobCheckList", "frontPads"], source),
    frontPadsV2: pathOr(
      {
        padThickness: {
          thickness: null,
          unavailable: null,
        },
        condition: null,
      },
      ["preJobCheckList", "frontPadsV2"],
      source
    ),
    frontRotors: pathOr(checkListItemDefault, ["preJobCheckList", "frontRotors"], source),
    rearPads: pathOr(checkListItemDefault, ["preJobCheckList", "rearPads"], source),
    rearPadsV2: pathOr(
      {
        padThickness: {
          thickness: null,
          unavailable: null,
        },
        condition: null,
      },
      ["preJobCheckList", "rearPadsV2"],
      source
    ),
    rearRotors: pathOr(checkListItemDefault, ["preJobCheckList", "rearRotors"], source),
    brakeFluid: pathOr(checkListItemDefault, ["preJobCheckList", "brakeFluid"], source),
    brakeShoes: pathOr(checkListItemDefault, ["preJobCheckList", "brakeShoes"], source),
    brakeDrums: pathOr(checkListItemDefault, ["preJobCheckList", "brakeDrums"], source),
    calipers: pathOr(checkListItemDefault, ["preJobCheckList", "calipers"], source),
    shocksStruts: pathOr(checkListItemDefault, ["preJobCheckList", "shocksStruts"], source),
    controlArms: pathOr(checkListItemDefault, ["preJobCheckList", "controlArms"], source),
    wheelHubs: pathOr(checkListItemDefault, ["preJobCheckList", "wheelHubs"], source),
    studs: pathOr(checkListItemDefault, ["preJobCheckList", "studs"], source),
    lugnuts: pathOr(checkListItemDefault, ["preJobCheckList", "lugnuts"], source),
    sparkPlugs: pathOr(checkListItemDefault, ["preJobCheckList", "sparkPlugs"], source),
    carBattery: pathOr(checkListItemDefault, ["preJobCheckList", "carBattery"], source),
    alternator: pathOr(checkListItemDefault, ["preJobCheckList", "alternator"], source),
    engineAirFilter: pathOr(checkListItemDefault, ["preJobCheckList", "engineAirFilter"], source),
    cabinAirFilter: pathOr(checkListItemDefault, ["preJobCheckList", "cabinAirFilter"], source),
    otherBrakeParts: pathOr(checkListItemDefault, ["preJobCheckList", "otherBrakeParts"], source),
    dashboardLights: pathOr(checkListItemDefault, ["preJobCheckList", "dashboardLights"], source),
    odometer: pathOr("", ["vehicleInfo", "odometer"], source),
    oilLife: pathOr(
      {
        estimatedNextOilChangeTimeline: null,
        nextOilChangeMileage: {
          mileage: null,
          unavailable: null,
        },
        nextOilChangeDate: {
          date: null,
          unavailable: null,
        },
      },
      ["preJobCheckList", "oilLife"],
      source
    ),
    tireTread: pathOr(
      {
        frontLeft: {
          depth: null,
          unavailable: null,
        },
        frontRight: {
          depth: null,
          unavailable: null,
        },
        rearLeft: {
          depth: null,
          unavailable: null,
        },
        rearRight: {
          depth: null,
          unavailable: null,
        },
      },
      ["preJobCheckList", "tireTread"],
      source
    ),
  };
};

export const preJobCheckListV2InitialValues = (source: Job | Invoice): PreJobCheckListV2FormValues => {
  return {
    odometer: source?.vehicleInfo?.odometer ?? "",
    licensePlate: source?.vehicleInfo?.licensePlate ?? "",
    licensePlateState: source?.vehicleInfo?.licensePlateState ?? "",
    brakeFluid: {
      colorGrade: source?.preJobCheckListV2?.brakeFluid?.colorGrade ?? "",
      ppm: source?.preJobCheckListV2?.brakeFluid?.ppm ?? null,
      level: source?.preJobCheckListV2?.brakeFluid?.level ?? null,
    },
    frontPads: {
      padsThickness: source?.preJobCheckListV2?.frontPads?.padsThickness ?? null,
      innerPadThickness: source?.preJobCheckListV2?.frontPads?.innerPadThickness ?? null,
      outerPadThickness: source?.preJobCheckListV2?.frontPads?.outerPadThickness ?? null,
      condition: source?.preJobCheckListV2?.frontPads?.condition ?? [],
    },
    frontRotors: {
      thicknessInches: source?.preJobCheckListV2?.frontRotors?.thicknessInches ?? null,
      condition: source?.preJobCheckListV2?.frontRotors?.condition ?? [],
    },
    rearPads: {
      padsThickness: source?.preJobCheckListV2?.frontPads?.padsThickness ?? null,
      innerPadThickness: source?.preJobCheckListV2?.rearPads?.innerPadThickness ?? null,
      outerPadThickness: source?.preJobCheckListV2?.rearPads?.outerPadThickness ?? null,
      condition: source?.preJobCheckListV2?.rearPads?.condition ?? [],
    },
    rearRotors: {
      thicknessInches: source?.preJobCheckListV2?.rearRotors?.thicknessInches ?? null,
      condition: source?.preJobCheckListV2?.rearRotors?.condition ?? [],
    },
    usesRearShoesAndDrums: source?.preJobCheckListV2?.usesRearShoesAndDrums ?? false,
    inspectedServices:
      {
        frontLeftCaliper: {
          included: source?.preJobCheckListV2?.inspectedServices?.frontLeftCaliper?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.frontLeftCaliper?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.frontLeftCaliper?.notes ?? null,
        },
        frontRightCaliper: {
          included: source?.preJobCheckListV2?.inspectedServices?.frontRightCaliper?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.frontRightCaliper?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.frontRightCaliper?.notes ?? null,
        },
        rearLeftCaliper: {
          included: source?.preJobCheckListV2?.inspectedServices?.rearLeftCaliper?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.rearLeftCaliper?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.rearLeftCaliper?.notes ?? null,
        },
        rearRightCaliper: {
          included: source?.preJobCheckListV2?.inspectedServices?.rearRightCaliper?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.rearRightCaliper?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.rearRightCaliper?.notes ?? null,
        },
        frontLeftHubAssemblyBearing: {
          included: source?.preJobCheckListV2?.inspectedServices?.frontLeftHubAssemblyBearing?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.frontLeftHubAssemblyBearing?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.frontLeftHubAssemblyBearing?.notes ?? null,
        },
        frontRightHubAssemblyBearing: {
          included: source?.preJobCheckListV2?.inspectedServices?.frontRightHubAssemblyBearing?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.frontRightHubAssemblyBearing?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.frontRightHubAssemblyBearing?.notes ?? null,
        },
        rearLeftHubAssemblyBearing: {
          included: source?.preJobCheckListV2?.inspectedServices?.rearLeftHubAssemblyBearing?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.rearLeftHubAssemblyBearing?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.rearLeftHubAssemblyBearing?.notes ?? null,
        },
        rearRightHubAssemblyBearing: {
          included: source?.preJobCheckListV2?.inspectedServices?.rearRightHubAssemblyBearing?.included ?? false,
          colorGrade: source?.preJobCheckListV2?.inspectedServices?.rearRightHubAssemblyBearing?.colorGrade ?? null,
          notes: source?.preJobCheckListV2?.inspectedServices?.rearRightHubAssemblyBearing?.notes ?? null,
        },
      } ?? inspectedServicesInitialState,
    privateNotes: source?.preJobCheckListV2?.privateNotes ?? "",
    rearDrums: {
      colorGrade: source?.preJobCheckListV2?.rearDrums?.colorGrade ?? null,
      condition: source?.preJobCheckListV2?.rearDrums?.condition ?? [],
    },
    rearShoes: {
      colorGrade: source?.preJobCheckListV2?.rearShoes?.colorGrade ?? null,
      condition: source?.preJobCheckListV2?.rearShoes?.condition ?? [],
    },
  };
};
