import { isFunctionTypeNode } from "typescript";
import {
  DRUMS_CONDITIONS_OPTIONS,
  PADS_CONDITION_OPTIONS,
  POSSIBLE_INSPECTED_SERVICES_LIST,
  ROTORS_CONDITION_OPTIONS,
  SHOES_CONDITIONS_OPTIONS,
} from "../../PreJobChecklistV2/constants";
import {
  FieldFunction,
  FieldObject,
  FieldProps,
  colorGrade,
  condition,
  innerPad,
  level,
  licensePlate as licensePlateField,
  licensePlateState,
  notes,
  outerPad,
  ppm,
  privateNotes,
  thicknessInches,
  pad,
} from "./fields";
import { FormItems } from "./forms";

type ChildItem = {
  item: SectionFunction | FieldFunction;
  props?: FieldProps | Sectionrops;
};

type Sectionrops = {
  children?: ChildItem[];
  parentName?: string;
  required?: boolean;
};

type BaseSectionProps = Sectionrops & {
  type?: "section" | "drawer";
  name?: string;
};

export interface SectionObject {
  itemType: "section" | "drawer";
  code?: string;
  name?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  children?: Record<string, SectionObject | FieldObject> | FormItems;
  required?: boolean;
}

type SectionFunction = (props?: Sectionrops) => SectionObject;

const baseSection = ({ name, parentName, children, type, ...restProps }: BaseSectionProps) => ({
  itemType: type ?? "section",
  code: name,
  children: Object.fromEntries(
    children.map(({ item, props }) => {
      const child = item({ parentName: parentName ? `${parentName}.${name}` : name, ...props });
      return [child?.code, child];
    })
  ),
  title: null,
  subTitle: null,
  description: null,
  required: null,
});

export const brakeFluid: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "brakeFluid",
    children: [
      {
        item: ppm,
        props: {
          required: true,
        },
      },
      {
        item: level,
        props: {
          required: true,
        },
      },
    ],
  }),
  title: "Brake Fluid",
});
export const brakeFluidCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "brakeFluid",
    children: [
      {
        item: colorGrade,
        props: {
          required: true,
        },
      },
      {
        item: level,
        props: {
          required: true,
        },
      },
    ],
  }),
  title: "Brake Fluid",
});
export const frontPads: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "frontPads",
    children: [
      {
        item: innerPad,
        props: {
          name: "innerPadThickness",
          required: true,
        },
      },
      {
        item: outerPad,
        props: {
          name: "outerPadThickness",
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: PADS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Front Pads",
});
export const frontPadsCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "frontPads",
    children: [
      {
        item: pad,
        props: {
          name: "padsThickness",
          label: "Pads Thickness",
          useAllRow: true,
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: PADS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Front Pads",
});
export const frontRotors: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "frontRotors",
    children: [
      {
        item: thicknessInches,
        props: {
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: ROTORS_CONDITION_OPTIONS,
        },
      },
    ],
  }),
  title: "Front Rotors",
  subTitle: "Thickness (inches)",
});
export const frontRotorsCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "frontRotors",
    children: [
      {
        item: condition,
        props: {
          required: true,
          options: ROTORS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Front Rotors",
});
export const rearShoes: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearShoes",
    children: [
      {
        item: colorGrade,
        props: {
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: SHOES_CONDITIONS_OPTIONS,
        },
      },
    ],
  }),
  title: "Rear Shoes",
});
export const rearDrums: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearDrums",
    children: [
      {
        item: colorGrade,
        props: {
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: DRUMS_CONDITIONS_OPTIONS,
        },
      },
    ],
  }),
  title: "Rear Drums",
});
export const rearPads: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearPads",
    children: [
      {
        item: innerPad,
        props: {
          name: "innerPadThickness",
          required: true,
        },
      },
      {
        item: outerPad,
        props: {
          name: "outerPadThickness",
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: PADS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Rear Pads",
});
export const rearPadsCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearPads",
    children: [
      {
        item: pad,
        props: {
          name: "padsThickness",
          label: "Pads Thickness",
          useAllRow: true,
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: PADS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Rear Pads",
});
export const rearRotors: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearRotors",
    children: [
      {
        item: thicknessInches,
        props: {
          required: true,
        },
      },
      {
        item: condition,
        props: {
          required: true,
          options: ROTORS_CONDITION_OPTIONS,
        },
      },
    ],
  }),
  title: "Rear Rotors",
});
export const rearRotorsCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "rearRotors",
    children: [
      {
        item: condition,
        props: {
          required: true,
          options: ROTORS_CONDITION_OPTIONS,
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Rear Rotors",
});
export const inspectedServices: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "inspectedServices",
    type: "drawer",
    children: POSSIBLE_INSPECTED_SERVICES_LIST.map((service) => ({
      item: ({ parentName }) => ({
        ...baseSection({
          parentName,
          name: service.name,
          children: [
            {
              item: colorGrade,
              props: {
                useAllRow: true,
              },
            },
            {
              item: notes,
              props: {
                useAllRow: true,
              },
            },
          ],
        }),
        title: service.label,
      }),
    })),
  }),
  title: "Additional Inspection Service",
});
export const inspectedServicesCore: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    name: "inspectedServices",
    type: "drawer",
    children: POSSIBLE_INSPECTED_SERVICES_LIST.map((service) => ({
      item: ({ parentName }) => ({
        ...baseSection({
          parentName,
          name: service.name,
          children: [
            {
              item: colorGrade,
              props: {
                useAllRow: true,
              },
            },
            {
              item: notes,
              props: {
                useAllRow: true,
              },
            },
          ],
        }),
        title: service.label,
      }),
    })),
  }),
  title: "Inspect Caliper / Hub Bearings (Optional)",
});
export const inspectionPrivateNotes: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    children: [
      {
        item: privateNotes,
        props: {
          useAllRow: true,
        },
      },
    ],
  }),
  title: "Inspection Private Notes",
});
export const licensePlate: SectionFunction = (props) => ({
  ...baseSection({
    ...props,
    children: [
      {
        item: licensePlateField,
      },
      {
        item: licensePlateState,
      },
    ],
  }),
  title: "License Plate",
  required: props?.required ?? true,
});
