import React from "react";
import { CheckCircle, Warning, Error } from "@material-ui/icons";
import { GOOD_CONDITION_OPTION } from "./constants";

export type ColorGradeOption = "Red" | "Yellow" | "Green";

export const ColorGradeIcon: React.FC<{
  colorGrade?: ColorGradeOption | null;
  className?: string;
}> = React.memo(function ColorGradeIcon({ colorGrade, className }) {
  if (colorGrade === "Green") {
    return (
      <div className={className}>
        {" "}
        <CheckCircle fontSize="small" style={{ color: "#00C853" }} />
      </div>
    );
  }

  if (colorGrade === "Yellow") {
    return (
      <div className={className}>
        <Warning fontSize="small" style={{ color: "#FFD600" }} />
      </div>
    );
  }

  if (colorGrade === "Red") {
    return (
      <div className={className}>
        <Error fontSize="small" style={{ color: "#D50000" }} />
      </div>
    );
  }

  return null;
});

// Shared
export const getConditionColorGrade = (condition: string[]): ColorGradeOption | null => {
  if (!Array.isArray(condition)) return null;
  if (condition.length === 0) return null;
  if (condition.includes(GOOD_CONDITION_OPTION)) return "Green";
  return "Red";
};

// Fluid
export const getFluidPpmColorGrade = (ppm: string): ColorGradeOption | null => {
  if (!ppm) return null;
  const ppmNumber = Number(ppm);
  if (ppmNumber < 30) {
    return "Green";
  } else if (ppmNumber < 200) {
    return "Yellow";
  } else if (ppmNumber >= 200) {
    return "Red";
  }
  return null;
};
export const getFluidLevelColorGrade = (level: string): ColorGradeOption | null => {
  if (level === "Full") return "Green";
  if (level === "Not full") return "Red";
  return null;
};

// Pads
export const getPadsThicknessColorGrade = (thickness: string): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;
  if (thicknessNumber <= 3) {
    return "Red";
  } else if (thicknessNumber <= 6) {
    return "Yellow";
  } else if (thicknessNumber > 6) {
    return "Green";
  }
  return null;
};

// Rotors
export const getRotorsThicknessColorGrade = (
  thickness: string | null,
  discardThickness: string | null = null
): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;

  if (!discardThickness) return null;
  const discardThicknessNumber = Number(discardThickness);
  if (Number.isNaN(discardThicknessNumber)) return null;

  if (thicknessNumber <= discardThicknessNumber) {
    return "Red";
  } else {
    return "Green";
  }
};
