import * as Yup from "yup";

const numericStringSchema = Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Must be a valid number");

const brakeFluidSchema = Yup.object().shape({
  ppm: numericStringSchema.required("Required"),
  level: Yup.string().required("Required"),
});

const coreBrakeFluidSchema = Yup.object().shape({
  colorGrade: Yup.string().required("Required"),
  level: Yup.string().required("Required"),
});

const inspectedServiceSchema = Yup.object().shape(
  {
    included: Yup.boolean().optional(),
    colorGrade: Yup.string().when("included", {
      is: (included) => !!included,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().optional(),
    }),
    notes: Yup.string().optional(),
  },
  [["included", "included"]]
);

const padsSchema = Yup.object().shape({
  innerPadThickness: numericStringSchema.required("Required"),
  outerPadThickness: numericStringSchema.required("Required"),
  condition: Yup.array().of(Yup.string()).required("Required").min(1, "Required"),
});

const corePadsSchema = Yup.object().shape({
  padsThickness: numericStringSchema.required("Required"),
  condition: Yup.array().of(Yup.string()).required("Required").min(1, "Required"),
});

const rotorsSchema = Yup.object().shape({
  thicknessInches: numericStringSchema.required("Required"),
});

const coreRotorsSchema = Yup.object().shape({
  condition: Yup.array().of(Yup.string()).required("Required").min(1, "Required"),
});

const shoesSchema = Yup.object().shape({
  colorGrade: Yup.string().required("Required"),
  condition: Yup.array().of(Yup.string()).required("Required").min(1, "Required"),
});

const drumsSchema = Yup.object().shape({
  colorGrade: Yup.string().required("Required"),
  condition: Yup.array().of(Yup.string()).required("Required").min(1, "Required"),
});

export const checkListV2Schema = Yup.object().shape(
  {
    odometer: numericStringSchema.required("Required"),
    brakeFluid: brakeFluidSchema.required("Required"),
    frontPads: padsSchema.required("Required"),
    frontRotors: rotorsSchema.required("Required"),
    usesRearShoesAndDrums: Yup.boolean().optional(),
    rearPads: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !usesRearShoesAndDrums,
      then: padsSchema.required("Required"),
      otherwise: Yup.object().nullable(),
    }),
    rearRotors: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !usesRearShoesAndDrums,
      then: rotorsSchema.required("Required"),
      otherwise: Yup.object().nullable(),
    }),
    rearShoes: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !!usesRearShoesAndDrums,
      then: shoesSchema,
      otherwise: Yup.object().nullable(),
    }),
    rearDrums: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !!usesRearShoesAndDrums,
      then: drumsSchema,
      otherwise: Yup.object().nullable(),
    }),
    privateNotes: Yup.string().optional(),
    inspectedServices: Yup.object().shape({
      frontLeftCaliper: inspectedServiceSchema,
      frontRightCaliper: inspectedServiceSchema,
      rearLeftCaliper: inspectedServiceSchema,
      rearRightCaliper: inspectedServiceSchema,
      frontLeftHubAssemblyBearing: inspectedServiceSchema,
      frontRightHubAssemblyBearing: inspectedServiceSchema,
      rearLeftHubAssemblyBearing: inspectedServiceSchema,
      rearRightHubAssemblyBearing: inspectedServiceSchema,
    }),
  },
  [
    ["frontPads", "frontPads"],
    ["usesRearShoesAndDrums", "usesRearShoesAndDrums"],
  ]
);

export const coreCheckListV2Schema = Yup.object().shape(
  {
    odometer: numericStringSchema.required("Required"),
    brakeFluid: coreBrakeFluidSchema.required("Required"),
    frontPads: corePadsSchema.required("Required"),
    frontRotors: coreRotorsSchema.required("Required"),
    usesRearShoesAndDrums: Yup.boolean().optional(),
    rearPads: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !usesRearShoesAndDrums,
      then: corePadsSchema.required("Required"),
      otherwise: Yup.object().nullable(),
    }),
    rearRotors: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !usesRearShoesAndDrums,
      then: coreRotorsSchema.required("Required"),
      otherwise: Yup.object().nullable(),
    }),
    rearShoes: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !!usesRearShoesAndDrums,
      then: shoesSchema,
      otherwise: Yup.object().nullable(),
    }),
    rearDrums: Yup.object().when("usesRearShoesAndDrums", {
      is: (usesRearShoesAndDrums) => !!usesRearShoesAndDrums,
      then: drumsSchema,
      otherwise: Yup.object().nullable(),
    }),
    privateNotes: Yup.string().optional(),
    inspectedServices: Yup.object().shape({
      caliper: inspectedServiceSchema,
      hubAssemblyBearing: inspectedServiceSchema,
    }),
  },
  [
    ["frontPads", "frontPads"],
    ["usesRearShoesAndDrums", "usesRearShoesAndDrums"],
  ]
);
