import { flow } from "fp-ts/lib/function";
import { applySpec, path, prop, propOr } from "ramda";
import {
  EJIDiscountFormValue,
  EJIItemFormValue,
  EJIPriceInfoFormValue,
  EJIPricingConfigFormValue,
  EJIServiceFormValue,
  EJIVendorUnitCostMultiplierFormValue,
  PartsCalcs,
  PromoCodeFormValue,
} from "../components/Forms/JobDetailsForm";
import {
  EjiDiscount,
  EjiDiscountInput,
  EjiItem,
  EjiItemInput,
  EjiPriceInfo,
  EjiPricingConfig,
  EjiPricingConfigInput,
  EjiService,
  EjiServiceInput,
  PossibleEjiItem,
  PossibleEjiItemInput,
  PossibleEjiService,
  PossibleEjiServiceInput,
  PromoCode,
  PromoCodeInput,
  VendorUnitCostMultiplier,
  VendorUnitCostMultiplierInput,
} from "../generated/nest-graphql";

export const formValuesToEJIServiceInputsSpec = (ejiServiceFormValues: EJIServiceFormValue[]) =>
  ejiServiceFormValues?.map(
    applySpec<EjiServiceInput>({
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      technicianPay: prop("technicianPay"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcsSpec),
      items: flow(prop("items"), formValuesToEJIItemInputsSpec),
    })
  );

export const formValuesToEJIItemInputsSpec = (ejiItemFormValues: EJIItemFormValue[]) =>
  ejiItemFormValues?.map(
    applySpec<EjiItemInput>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: path(["productSelection", "part", "id"]),
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: propOr(false, "overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: path(["orderItem", "partsStore", "id"]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
      marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
      marketLaborRateUsed: prop("marketLaborRateUsed"),
    })
  );

export const formValuesToPossibleEJIServiceInputs = (ejiServiceFormValues: EJIServiceFormValue[]) =>
  ejiServiceFormValues?.map(
    applySpec<PossibleEjiServiceInput>({
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      technicianPay: prop("technicianPay"),
      items: flow(prop("items"), formValuesToPossibleEJIItemInputs),
    })
  );

export const formValuesToPossibleEJIItemInputs = (ejiItemsFormValues: EJIItemFormValue[]) =>
  ejiItemsFormValues?.map(
    applySpec<PossibleEjiItemInput>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: path(["productSelection", "part", "id"]),
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: propOr(false, "overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: path(["orderItem", "partsStore", "id"]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
      marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
      marketLaborRateUsed: prop("marketLaborRateUsed"),
    })
  );

export const possibleEJIServicesToFormValuesSpec = (possibleEJIServices: PossibleEjiService[]) =>
  possibleEJIServices?.map(
    applySpec<EJIServiceFormValue>({
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      customerPrice: prop("customerPrice"),
      technicianPay: prop("technicianPay"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcsSpec),
      items: flow(prop("items"), possibleEJIItemsToFormValuesSpec),
      inInvoice: prop("inEstimate"),
    })
  );

export const partsCalcsSpec = (partCalcs): PartsCalcs =>
  applySpec<PartsCalcs>({
    marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
    overriddenCustomerPrice: prop("overriddenCustomerPrice"),
    totalPrice: prop("totalPrice"),
    vendorCost: prop("vendorCost"),
  })(partCalcs);

export const possibleEJIItemsToFormValuesSpec = (possibleEJIItems: PossibleEjiItem[]) =>
  possibleEJIItems?.map(
    applySpec<EJIItemFormValue>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: {
          id: path(["productSelection", "part", "id"]),
          partId: path(["productSelection", "part", "partId"]),
          productName: path(["productSelection", "part", "productName"]),
          label: path(["productSelection", "part", "label"]),
          vendorUnitCost: path(["productSelection", "part", "vendorUnitCost"]),
          type: path(["productSelection", "part", "type"]),
        },
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: prop("overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: path(["orderItem", "partsStore"]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
      marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
      marketLaborRateUsed: prop("marketLaborRateUsed"),
    })
  );

export const ejiServicesToFormValuesSpec = (ejiServices: EjiService[]) =>
  ejiServices?.map(
    applySpec<EJIServiceFormValue>({
      id: prop("id"),
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      customerPrice: prop("customerPrice"),
      technicianPay: prop("technicianPay"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcsSpec),
      items: flow(prop("items"), ejiItemsToFormValuesSpec),
      updatedAt: prop("updatedAt"),
      createdAt: prop("createdAt"),
      inInvoice: prop("inEstimate"),
    })
  );

export const ejiItemsToFormValuesSpec = (ejiItems: EjiItem[]) =>
  ejiItems?.map(
    applySpec<EJIItemFormValue>({
      id: prop("id"),
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: {
          id: path(["productSelection", "part", "id"]),
          partId: path(["productSelection", "part", "partId"]),
          productName: path(["productSelection", "part", "productName"]),
          label: path(["productSelection", "part", "label"]),
          vendorUnitCost: path(["productSelection", "part", "vendorUnitCost"]),
          type: path(["productSelection", "part", "type"]),
        },
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: prop("overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: path(["orderItem", "partsStore"]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
      marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
      marketLaborRateUsed: prop("marketLaborRateUsed"),
    })
  );

export const ejiServiceFormValuesToLimitedFormValuesSpec = (ejiServiceFormValues: EJIServiceFormValue[]) =>
  ejiServiceFormValues?.map(
    applySpec<EJIServiceFormValue>({
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      customerPrice: prop("customerPrice"),
      technicianPay: prop("technicianPay"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcsSpec),
      items: flow(prop("items"), ejiItemFormValuesToLimitedFormValuesSpec),
      inInvoice: prop("inEstimate"),
    })
  );

export const ejiItemFormValuesToLimitedFormValuesSpec = (ejiItemFormValues: EJIItemFormValue[]) =>
  ejiItemFormValues?.map(
    applySpec<EJIItemFormValue>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: prop("overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: path(["orderItem", "partsStore"]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
      marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
      marketLaborRateUsed: prop("marketLaborRateUsed"),
    })
  );

export const ejiDiscountsToFormValuesSpec = (ejiDiscounts?: EjiDiscount[]) =>
  ejiDiscounts?.map(
    applySpec<EJIDiscountFormValue>({
      type: prop("type"),
      amount: prop("amount"),
      reason: prop("reason"),
      total: prop("total"),
    })
  );

export const ejiPromoCodesToFormValuesSpec = (promoCodes?: PromoCode[]) =>
  promoCodes?.map(
    applySpec({
      name: prop("name"),
      code: prop("code"),
      coupon: {
        name: path(["coupon", "name"]),
        valid: path(["coupon", "valid"]),
        discount: {
          type: path(["coupon", "discount", "type"]),
          amount: path(["coupon", "discount", "amount"]),
          reason: path(["coupon", "discount", "reason"]),
        },
      },
    })
  );

export const formValuesToEJIDiscountInputs = (ejiDiscountFormValues?: EJIDiscountFormValue[]) =>
  ejiDiscountFormValues?.map(
    applySpec<EjiDiscountInput>({
      type: prop("type"),
      amount: prop("amount"),
      reason: prop("reason"),
    })
  );

export const formValuesToEJIPromoCodeInputs = (promoCodeFormValues?: PromoCodeFormValue[]) =>
  promoCodeFormValues?.map(
    applySpec<PromoCodeInput>({
      code: prop("code"),
      name: prop("name"),
      coupon: {
        valid: path(["coupon", "valid"]),
        name: path(["coupon", "name"]),
        discount: {
          type: path(["coupon", "discount", "type"]),
          amount: path(["coupon", "discount", "amount"]),
          reason: path(["coupon", "discount", "reason"]),
        },
      },
    })
  );

export const formValuesToEJIDiscountInput = (ejiDiscountFormValue) =>
  applySpec<EjiDiscountInput>({
    type: prop("type"),
    amount: prop("amount"),
    reason: prop("reason"),
  })(ejiDiscountFormValue);

export const vendorUnitCostMultipliersToFormValues = (vendorUnitCostMultipliers?: VendorUnitCostMultiplier[]) =>
  vendorUnitCostMultipliers?.map(
    applySpec<EJIVendorUnitCostMultiplierFormValue>({
      startRangePrice: prop("startRangePrice"),
      endRangePrice: prop("endRangePrice"),
      multiplier: prop("multiplier"),
    })
  );

export const ejiPricingConfigToFormValue = (ejiPricingConfig: EjiPricingConfig) =>
  applySpec<EJIPricingConfigFormValue>({
    laborRate: prop("laborRate"),
    vendorUnitCostMultipliers: flow(prop("vendorUnitCostMultipliers"), vendorUnitCostMultipliersToFormValues),
  })(ejiPricingConfig);

export const ejiPriceInfoToFormValue = (ejiPriceInfo?: EjiPriceInfo) =>
  applySpec<EJIPriceInfoFormValue>({
    pricingConfig: flow(prop("pricingConfig"), ejiPricingConfigToFormValue),
    partsTotal: prop("partsTotal"),
    laborTotal: prop("laborTotal"),
    feesTotal: prop("feesTotal"),
    subTotal: prop("subTotal"),
    discounts: flow(prop("discounts"), ejiDiscountsToFormValuesSpec),
    discountTotal: prop("combinedDiscountTotal"),
    finalPartsTotal: prop("finalPartsTotal"),
    finalLaborTotal: prop("finalLaborTotal"),
    finalFeesTotal: prop("finalFeesTotal"),
    finalSubTotal: prop("finalSubTotal"),
    partsTax: prop("partsTax"),
    laborTax: prop("laborTax"),
    totalTax: prop("totalTax"),
    amountDue: prop("amountDue"),
    promoCodes: prop("promoCodes"),
  })(ejiPriceInfo);

export const formValueToVendorUnitCostMultiplierInput = (
  vendorUnitCostMultiplierFormValues?: EJIVendorUnitCostMultiplierFormValue[]
) =>
  vendorUnitCostMultiplierFormValues?.map(
    applySpec<VendorUnitCostMultiplierInput>({
      startRangePrice: prop("startRangePrice"),
      endRangePrice: prop("endRangePrice"),
      multiplier: prop("multiplier"),
    })
  );

export const formValueToEJIPricingConfigInput = (ejiPricingConfigFormValue: EJIPricingConfigFormValue) =>
  applySpec<EjiPricingConfigInput>({
    laborRate: prop("laborRate"),
    vendorUnitCostMultipliers: flow(prop("vendorUnitCostMultipliers"), formValueToVendorUnitCostMultiplierInput),
  })(ejiPricingConfigFormValue);
